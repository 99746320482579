import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Sjekklister" />
    <div className="longtext">
      <h2>Sjekklister</h2>

      <h3>Lage/endre sjekklister</h3>
      <ol>
        <li>Opprett (eller endre) en liste</li>
        <li>
          Velg <b>Oppførsel -&gt; Sjekkliste</b> for at listen skal oppføre seg
          som en sjekkliste
        </li>
        <li>Fyll listen med oppgaver og seksjoner som vanlig</li>
      </ol>

      <h3>Fullføre sjekklister</h3>
      <ol>
        <li>Fullfør oppgavene som normalt</li>
        <li>
          Når du har fullført alle får du forslag om å starte listen på nytt.{" "}
        </li>
      </ol>

      <h3>Starte listen på nytt</h3>
      <p>
        Via listemenyen (... på iOS, eller klikk på navnet på listen på Mac) og
        velg Start listen på nytt.
      </p>

      <h3>Forbedre sjekklistene dine</h3>
      <p>
        Var det noe du glemte i pakkelisten din? Eller noe du savnet til tacoen?
        Når du er ferdig med sjekklisten vil du få et forslag om å legge til
        ting du savnet til neste gang, slik at sjekklistene dine blir enda
        bedre. Du kan selvsagt legge til oppgaver fortløpende også, som blir med
        neste gang du starter listen på nytt.
      </p>

      <h3>Tips</h3>
      <ul>
        <li>
          Legg sjekklistene dine under Biblioteket for å unngå at de dukker opp
          i Neste-listen og i den vanlige listeoversikten
        </li>
        <li>Del sjekklistene dine med andre</li>
        <li>
          Del inn lister i seksjoner, så kan du raskt fullføre alle oppgaver i
          en seksjon samtidig via ... knappen. Dette er spesielt nyttig på
          f.eks. pakkelister, hvor du kan ha ulike scenarier for ulike turer du
          skal på. Du oppretter en seksjon ved å dra den grønne + knappen mot
          kanten av listen, der du vil sette inn seksjonen.
        </li>
      </ul>
    </div>
  </Layout>
)

export default Page
